import React from "react"
import styled from "styled-components"
import _ from "lodash"
import Image from "gatsby-image"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => {
  const { t } = useTranslation()
  const members = _.chain(data)
    .get("allContentfulMembers.edges", [])
    .map(({ node }) => node)
    .sortBy(["position"])
    .value()
  return (
    <Layout>
      <SEO title="About us | Clapesque" />
      <div style={{ textAlign: "center", marginBottom: 20 }}>
        <div style={{ textAlign: "justify" }}>{t("pages.aboutUs.tagline")}</div>
        <div style={{ height: 40 }} />
        <div style={{ fontWeight: "bold", textTransform: "uppercase" }}>
          {t("pages.aboutUs.us")}
        </div>
      </div>
      <TeamList>
        {members.map(({ name, role, url, picture }) => (
          <a
            key={`team.${name}`}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TeamItem>
              <TeamItemImage alt={name} sizes={picture?.sizes} />
              <TeamItemName>{name}</TeamItemName>
              <TeamItemRole>{role}</TeamItemRole>
            </TeamItem>
          </a>
        ))}
      </TeamList>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query TeamQuery {
    allContentfulMembers {
      edges {
        node {
          id
          name
          role
          url
          position
          picture {
            sizes(maxWidth: 800) {
              ...GatsbyContentfulSizes
            }
          }
        }
      }
    }
  }
`

const TeamList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  a {
    text-decoration: none;
  }
  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
`

const TeamItem = styled.div`
  color: black;
  text-align: center;
  text-decoration: none;
  transition: transform 100ms ease-in;
  &:hover {
    transform: translatey(-3px);
  }
  @media (max-width: 1100px) {
    margin: 10px 0;
  }
`

const TeamItemImage = styled(Image)`
  border-radius: 100%;
  max-width: 180px;
  margin: auto;
`
const TeamItemName = styled.div`
  margin-top: 20px;
  font-weight: bold;
`

const TeamItemRole = styled.div`
  color: rgba(0, 0, 0, 0.6);
`
